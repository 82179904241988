import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INIT_IS_BACKGROUND, INIT_LIGHT_MODE } from '../../utils/variables';

type HomeState = {
  openProfileModal: boolean;
  openPasswordModal: boolean;
  windowWidth: number;
  windowHeight: number;
  pagesWithBackground: boolean;
  lightMode: boolean;
  pageName: string;
};

const initialState: HomeState = {
  openProfileModal: false,
  openPasswordModal: false,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  pagesWithBackground: INIT_IS_BACKGROUND,
  lightMode: INIT_LIGHT_MODE,
  pageName: '',
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setOpenProfileModal(state, action: PayloadAction<boolean>) {
      state.openProfileModal = action.payload;
    },
    setOpenPasswordModal(state, action: PayloadAction<boolean>) {
      state.openPasswordModal = action.payload;
    },
    setWindowWidth(state, action: PayloadAction<number>) {
      state.windowWidth = action.payload;
    },
    setWindowHeight(state, action: PayloadAction<number>) {
      state.windowHeight = action.payload;
    },
    setPagesWithBackground(state, action: PayloadAction<boolean>) {
      state.pagesWithBackground = action.payload;
    },
    setLightMode(state, action: PayloadAction<boolean>) {
      state.lightMode = action.payload;
    },
    setPageName(state, action: PayloadAction<string>) {
      state.pageName = action.payload;
    },
  },
});

export const homeActions = homeSlice.actions;

export default homeSlice.reducer;
