import React, { Dispatch, SetStateAction } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { appbarVariables } from '../../utils/variables';
import { useNavigate } from 'react-router-dom';
import { useUserItems } from './menuItems/userItems';

type UserMenuProps = {
  anchorElUser: HTMLElement | null;
  setAnchorElUser: Dispatch<SetStateAction<HTMLElement | null>>;
};

const UserMenu: React.FC<UserMenuProps> = ({ anchorElUser, setAnchorElUser }) => {
  const navigate = useNavigate();
  const userItems = useUserItems();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickUserItem = (href: string, functionProp: () => void) => {
    setAnchorElUser(null);
    if (href) navigate(href);
    else functionProp();
  };

  return (
    <>
      <div>
        <Tooltip arrow title="User">
          <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenUserMenu} color="inherit" className="hover:scale-150">
            <AccountCircle fontSize={appbarVariables.userIconsize} />
          </IconButton>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {userItems.map((item) => (
            <MenuItem key={item.label} onClick={() => handleClickUserItem(item.href, item.functionProp)}>
              <ListItemIcon className="pr-5">{item.icon}</ListItemIcon>
              <Typography textAlign="center">{item.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default UserMenu;
